import React, { useState, useEffect } from 'react';

import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTable from 'react-data-table-component';

import { allColumnDefinitions } from './ColumnDefinitions';
import styles from './ListComponent.module.scss';
import { usePermissions } from '../../hooks/usePermissions';


function ListComponent({ title, columns, data=[], formData,
  searchKeys=null, searchBarFullSize=false }) {
  /**
   * Important! Each value in columns should have a corresponding mapping
   * in ColumnDefinitions.js
   * For possible fields refer to:
   * https://react-data-table-component.netlify.app/?path=/docs/api-columns--page
   */
  console.assert(columns.every((column) => Object.keys(allColumnDefinitions).includes(column)));
  const [searchTerm, setSearchTerm] = useState('');
  const [displayData, setDisplayData] = useState(data);
  const {userPermissions} = usePermissions();
  const isAdmin = userPermissions.role === 'Admin';
  const columnDefs = columns.filter((column) => {
    if (!isAdmin) return !column.endsWith('Actions');
    else return column;
  }).map((column) => allColumnDefinitions[column]);

  const customStyles = {
    responsiveWrapper: {
      style: {
        overflow: 'visible',
      },
    },
  };

  const filterData = () => {
    const lowercase = searchTerm.toLowerCase();
    setDisplayData(data.filter((item) => {
      return searchKeys.some((key) => item[key].toString().toLowerCase().startsWith(lowercase));
    }));
  };

  useEffect(() => {
    if (searchKeys) {
      filterData();
    } else {
      setDisplayData(data);
    }
  }, [searchTerm, data]);

  return (
    <div className={styles.listComponentContainer}>
      {searchKeys != null && (
        <div
          className={`${styles.searchBarContainer} ${
            searchBarFullSize ? styles.fullSize : ''
          }`}
        >
          <div className={styles.searchBar}>
            <FontAwesomeIcon icon={faSearch} />
            <input
              id="search"
              name="search"
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      )}
      <div className={styles.tableWrapper}>
        <DataTable
          columns={columnDefs}
          data={displayData}
          pagination
          responsive
          fixedHeader
          fixedHeaderScrollHeight="auto" // Adjust height dynamically
          customStyles={customStyles}
        />
      </div>
    </div>
  );
}

export default ListComponent;

