export const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;
export const VERSION_NUMBER = process.env.REACT_APP_VERSION_NUMBER;
export const PAPERPLANES_ID = '4085';
export const SHOPIFY_CONFLUENCE = 'https://flypaperplanes.atlassian.net/wiki/'+'spaces/PCEFS/pages/68878337/Allow+Paperplanes+access+to+Shopify+APIs';
export const KLAVIYO_CONFLUENCE = 'https://flypaperplanes.atlassian.net/wiki/' + 'spaces/PCEFS/pages/266010625/Allow+Paperplanes+access+to+Klaviyo';
export const OMETRIA_API_KEY_DOC = 'https://support.ometria.com/hc/en-gb/' + 'articles/9882706389917-Creating-a-new-API-key';
export const RECHARGE_API_DOC = 'https://docs.getrecharge.com/docs/recharge-api-key';
export const userRoles = {
  ADMIN: 'Admin',
  CRM_MANAGER: 'Crm_manager',
  ANALYST: 'Analyst',
  CUSTOMER_SUPPORT: 'Customer_support',
  DESIGNER: 'Designer',
  DEVELOPER: 'Developer',
};
