import React, { useState, useEffect } from 'react';

import { faBan, faUserLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Blacklist from './Blacklist';
import styles from './Customer.module.scss';
import GDPR from './GDPR';
import Spinner from '../../components/Spinner/Spinner';
import TabLayout from '../../components/TabLayout/TabLayout';
import { PAPERPLANES_ID } from '../../config';
import useCompanyOptions from '../../hooks/useCompanyOptions';
import { usePermissions } from '../../hooks/usePermissions';

function Customer() {
  const [loading, setLoading] = useState(false);
  const { companyOptions, loadCompanyOptions } = useCompanyOptions();
  const {userPermissions} = usePermissions();
  const isPPAcount = userPermissions.companyId === PAPERPLANES_ID;
  const [companyId, setCompanyId] = useState(isPPAcount ? '' : userPermissions.companyId);

  useEffect(() => {
    if (isPPAcount) {
      const loadCompanies = async () => {
        setLoading(true);
        await loadCompanyOptions();
        setLoading(false);
      };
      loadCompanies();
    }
  }, []);

  return (<div className={styles.mainContainer}>
    {loading && <Spinner loading={loading} />}
    <div className={styles.heading}><h3>{isPPAcount ? <>
      <span className={styles.selectCompany}>
        <select value={companyId} onChange={(e) => setCompanyId(e.target.value)}>
          <option value='' disabled>Select a company</option>
          {companyOptions.map((c, i) => <option key={i} value={c.value}>{c.label}</option>)}
        </select>
      </span>
    </> : 'Manage Customers'}</h3></div>
    {!loading && companyId && <TabLayout tabs={[
      {label: <div>
        <FontAwesomeIcon icon={faBan} /> Opt-Out
      </div>, render: () => <Blacklist companyId={companyId} />},
      {label: <div>
        <FontAwesomeIcon icon={faUserLock} /> GDPR
      </div>, render: () => <GDPR companyId={companyId} />},
    ]} />}
  </div>);
}

export default Customer;
