const campaigns = {
  ABD: {
    displayName: 'Abandoned Baskets',
    value: 'ABD',
    dataSource: 'crm',
  },
  KLAVIYO: {
    displayName: 'Klaviyo',
    value: 'KLAVIYO',
    dataSource: 'klaviyo',
  },
  NBP: {
    displayName: 'Next Best Purchase',
    value: 'NBP',
    dataSource: 'crm',
  },
  WINBACK: {
    displayName: 'Winback',
    value: 'WIB',
    dataSource: 'crm',
  },
  APIWEBHOOK: {
    displayName: 'API/Webhook',
    value: 'APIWEBHOOK',
    dataSource: 'api-webhook',
  },
  getDisplayName(value) {
    return Object.values(this).find((c) => typeof c == 'object' && c.value === value)?.displayName;
  },
  getDataSource(value) {
    return Object.values(this).find((c) => typeof c == 'object' && c.value === value).dataSource;
  },
};

const audienceTargeting = {
  DEFAULT: {
    displayName: 'Default',
    value: 'NBP',
  },
  RFB: {
    displayName: 'Removed From Basket',
    value: 'RFB',
  },
  BNB: {
    displayName: 'Browse Not Bought',
    value: 'BNB',
  },
  getDisplayName(value) {
    return Object.values(this).find((c) => typeof c == 'object' && c.value === value)?.displayName;
  },
};

const campaignFrequency = {
  DAILY: {
    displayName: 'Daily Automation',
    value: '0',
  },
  ONE_TIME: {
    displayName: 'One Off Campaign',
    value: '1',
  },
};

export { campaigns, audienceTargeting, campaignFrequency };
