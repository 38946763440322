import React, { useState } from 'react';

import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, Transition } from '@headlessui/react';
import './MenuButton.scss';
import { toast } from 'react-toastify';

import { PAPERPLANES_ID } from '../../config';
import { usePermissions } from '../../hooks/usePermissions';
import CompanyService from '../../services/CompanyService';
import CompanyDetailsForm from '../CompanyDetailsForm/CompanyDetailsForm';
import ModalForm from '../Modal/ModalForm';
import 'react-toastify/dist/ReactToastify.css';

function CompanyMenuButton({ data, companyId, refresh }) {
  const [changeStatusFormOpen, setChangeStatusFormOpen] = useState(false);
  const [editPopUpOpen, setEditPopUpOpen] = useState(false);
  const [deletePopUpOpen, setDeletePopUpOpen] = useState(false);
  const [clickYAtBottom, setClickYAtBottom] = useState(false);
  const {userPermissions} = usePermissions();
  const isPPAdmin = userPermissions.role === 'Admin' && userPermissions.companyId === PAPERPLANES_ID;

  const responseHandling = (response, setPopUpOpen) => {
    if (response.success) {
      setPopUpOpen(false);
      refresh();
    } else {
      if (response.error && response.error.errors) {
        const errorMessages = response.error.errors.map((err) => err.detail).join('\n');
        toast.error(errorMessages);
      }
    }
  };
  const editCompany = async (data) => {
    ['excluded_ip_addresses', 'urls'].forEach((key) => {
      if (data[key]) {
        data[key] = [data[key]];
      } else {
        data[key] = [];
      }
    });
    try {
      const response = await CompanyService.editCompany(companyId, data);
      responseHandling(response, setEditPopUpOpen);
    } catch (e) {
      toast.error('An error occured editing selected company');
    }
  };
  const changeCompanyStatus = async () => {
    const newStatus = data.status === 'Active' ? 'Inactive' : 'Active';
    try {
      const response = await CompanyService.changeCompanyStatus(companyId, newStatus);
      responseHandling(response, setChangeStatusFormOpen);
    } catch (e) {
      toast.error('An error occured when changing status.');
      throw e;
    }
  };
  const deleteCompany = async () => {
    try {
      const response = await CompanyService.deleteCompany(companyId);
      responseHandling(response, setDeletePopUpOpen);
    } catch (e) {
      toast.error('An error occured deleting selected company');
    }
  };

  const handleMenuButtonClick = (event) => {
    if (event.clientY - 60 > window.innerHeight / 2) setClickYAtBottom(true);
    else setClickYAtBottom(false);
  };

  return (
    <div className="menu-container">
      <Menu as="div">
        {({ open }) => (
          <>
            <Menu.Button className="menu-button" onClick={handleMenuButtonClick}>
              <FontAwesomeIcon icon={faEllipsisV} />
            </Menu.Button>

            <Transition
              show={open}
              as={React.Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className={`menu-items
              ${clickYAtBottom ? 'menu-items-bottom' : ''}`}>
                {isPPAdmin && <Menu.Item>
                  {({ active }) => (
                    <button
                      // disabled
                      className={`${active ? 'active' : ''}`}
                      onClick={() => setEditPopUpOpen(true)}
                    >Edit Company</button>
                  )}
                </Menu.Item>}
                {companyId !== PAPERPLANES_ID && isPPAdmin && <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${active ? 'active' : ''}`}
                      onClick={() => setChangeStatusFormOpen(true)}
                    >{`Make company ${data.status === 'Active' ? 'inactive' : 'active'}`}</button>
                  )}
                </Menu.Item>}
                <Menu.Item>
                  {({ active }) => (
                    <button disabled className={`${active ? 'active' : ''}`}>Check tags</button>
                  )}
                </Menu.Item>
                {companyId !== PAPERPLANES_ID && isPPAdmin && <Menu.Item>
                  {({ active }) => (
                    <button
                      disabled
                      className={`${active ? 'active' : ''}`}
                      onClick={() => setDeletePopUpOpen(true)}
                    >
                      {/* <span className='delete-button'>Delete Company</span> */}
                      Delete Company
                    </button>
                  )}
                </Menu.Item>}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
      {changeStatusFormOpen && <ModalForm
        isOpen={changeStatusFormOpen}
        onClose={() => setChangeStatusFormOpen(false)}
        title={`Make company ${data.status === 'Active' ? 'Inactive' : 'Active'}`}
        description='Are you sure?'
        onSubmit={() => changeCompanyStatus()}
      />}
      {editPopUpOpen && <CompanyDetailsForm
        popUpOpen={editPopUpOpen}
        setPopUpOpen={setEditPopUpOpen}
        formTitle='Edit Company'
        onSubmit={(data) => editCompany(data)}
        placeholder={data}
      />}
      {deletePopUpOpen && <ModalForm
        isOpen={deletePopUpOpen}
        onClose={() => setDeletePopUpOpen(false)}
        title='Delete company?'
        description={'This action cannot be reversed. If you are absolutely ' + 'sure please type "permanently delete" in the box.'}
        onSubmit={() => deleteCompany()}
        config={[
          {
            name: 'permanently-delete',
            label: 'Are you sure?',
            placeholder: 'permanently delete',
            type: 'text',
            validation: {
              required: 'Empty!',
              pattern: {
                value: /^permanently delete$/,
                message: 'Invalid!',
              },
            },
          },
        ]}
      />}
    </div>
  );
}

export default CompanyMenuButton;
