import React, { useState, useEffect } from 'react';

import DataTable from 'react-data-table-component';

import styles from './ListComponent.module.scss';
import Spinner from '../Spinner/Spinner';

function PaginatedDataTable({ columns, getData }) {
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const fetchData = async (page, pageSize) => {
    setLoading(true);
    const res = await getData(page, pageSize);
    if (res.success) {
      setData(res.data.results);
      setTotalRows(res.data.count);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const customStyles = {
    responsiveWrapper: {
      style: {
        overflow: 'visible',
      },
    },
  };

  return (<div className={styles.listComponentContainer}>
    <div className={styles.tableWrapper}>
      {loading && <Spinner loading={loading} />}
      {!loading && <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationDefaultPage={currentPage}
        onChangePage={handlePageChange}
        paginationComponentOptions={{
          noRowsPerPage: true,
        }}
        customStyles={customStyles}
      />}
    </div>
  </div>);
}

export default PaginatedDataTable;
