import React, { useState, useEffect } from 'react';

import { faLaptop, faGlobe, faSquareMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

import styles from './CreateCampaign.module.scss';
import { campaignFrequency } from './createCampaignEnums';
import { DataSourceConnectionContent } from '../../components/DataSourceConnectionContent/DataSourceConnectionContent';
import Form from '../../components/Form/Form';
import Input from '../../components/Form/Input';
import TiledRadioField from '../../components/Inputs/TiledRadioField';
import ToggleSwitch from '../../components/Inputs/ToggleSwitch';
import ModalPopUp from '../../components/Modal/ModalPopUp';
import { useFormContext } from '../../hooks/useForm';
import CampaignService from '../../services/CampaignService';

function KlaviyoForm({ id, onSubmit, checkSegmentId, mailableCount }) {
  const { validate, formData } = useFormContext();
  const [popUpOpen, setPopUpOpen] = useState(false);

  const validateAndSubmit = async () => {
    if (validate({'list_id': {required: 'Empty!'}})) {
      const segmentExists = await checkSegmentId(formData['list_id']);
      if (segmentExists) {
        console.log('mailable count', mailableCount);
        setPopUpOpen(true);
      } else {
        toast.error('Segment ID doesn\'t exist');
      }
    }
  };

  return (<>
    <ModalPopUp isOpen={popUpOpen} onClose={() => setPopUpOpen(false)} title='Mailable Customers'>
      <DataSourceConnectionContent mailableCount={mailableCount} />
      <div className={styles.buttonContainer}>
        <button className={styles.secondaryButton} type='button' onClick={() => setPopUpOpen(false)}>Close</button>
        <button className={styles.primaryButton} type='button' onClick={onSubmit}>Continue</button>
      </div>
    </ModalPopUp>
    <Form id={id} onSubmit={validateAndSubmit}>
      <Input type='text' name='list_id' label='Klaviyo Segment ID' defaultValue='' placeholder='Segment ID' />
    </Form>
  </>);
}

function TargetBetweenDatesField({ text }) {
  const { setValue, register, errors } = useFormContext();
  return (<>
    {text}
    <div className={styles.targetDatesContainer}>
      <div className={styles.targetDate}>
        <input type='date' name='target_from_date' id='target_from_date' 
          {...register('target_from_date', '')}
          onChange={(e) => setValue('target_from_date', e.target.value)} />
      </div>
      and
      <div className={styles.targetDate}>
        <input type='date' name='target_to_date' id='target_to_date' 
          {...register('target_to_date', '')}
          onChange={(e) => setValue('target_to_date', e.target.value)} />
      </div>
      <div>
        {errors['target_from_date'] && <div className={styles.error}>{errors['target_from_date']}</div>}
        {errors['target_to_date'] && <div className={styles.error}>{errors['target_to_date']}</div>}
      </div>
    </div>
  </>);
}

function TargetDaysAgoField({ text, defaultValue }) {
  return (<div className={styles.content}>
    <span>{text}</span>
    <div className={styles.numberContainer}>
      <Input type='number' name='target_days_ago' defaultValue={defaultValue} min={0} />
    </div>
    <span>day(s) ago</span>
  </div>);
}

const dateRangeOrDaysAgoSchema = (isOneTime) => isOneTime ? {
  'target_from_date': {required: 'Start date cannot be empty'},
  'target_to_date': {required: 'End date cannot be empty', 
    custom: {
      func: (v, data) => v > data['target_from_date'],
      message: 'End date must be after start date',
    }},
} :
{
  'target_days_ago': {required: 'Empty!', min: 0},
};


function ABDForm({ id, onSubmit }) {
  const { formData, validate } = useFormContext();
  const isOneTime = formData['is_one_time'] === campaignFrequency.ONE_TIME.value;

  const validateAndSubmit = () => {
    const schema = dateRangeOrDaysAgoSchema(isOneTime);

    if (validate(schema)) {
      onSubmit();
    }
  };

  return (
    <Form id={id} onSubmit={validateAndSubmit}>
      {isOneTime ? 
        <TargetBetweenDatesField text='Target customers who abandoned a basket between' /> : 
        <TargetDaysAgoField text='Target customers who abandoned a basket' defaultValue={1} />}
    </Form>
  );
}

function IncludePurchasedProductsField({ defaultValue=false }) {
  return (<div className={styles.campaignControl}>
    <div className={styles.contentHeader}>
      <b>Include Purchased Products</b>
      <div className={styles.switchContainer}><ToggleSwitch name='include_purchased_products' defaultValue={defaultValue} /></div>
    </div>
    <div>Do you want to include products that a customer has previously purchased in the recommendations?</div>
  </div>);
}

function NBPForm({ id, onSubmit }) {
  const { formData, validate } = useFormContext();
  const isOneTime = formData['is_one_time'] === campaignFrequency.ONE_TIME.value;
  
  const strategyOptions = [
    {
      title: 'Default', value: 'NBP',
      description: 'Target all mailable customers',
      icon: <FontAwesomeIcon icon={faGlobe} />,
    },
    {
      title: 'Removed From Basket', value: 'RFB',
      description: 'Target customers who have removed product(s) from their cart before making a purchase',
      icon: <FontAwesomeIcon icon={faSquareMinus} />,
    },
    {
      title: 'Browse Not Bought', value: 'BNB',
      description: 'Target customers who interacted with any other product(s) which they didn’t purchase',
      icon: <FontAwesomeIcon icon={faLaptop} />,
    },
  ];

  const validateAndSubmit = () => {
    console.log(formData);
    const schema = {...dateRangeOrDaysAgoSchema(isOneTime), 
      'include_purchased_products': {required: 'Empty!'},
      'strategy': {required: 'Empty!'},
    };
    
    if (validate(schema)) {
      onSubmit();
    }
  };

  return (
    <Form id={id} onSubmit={validateAndSubmit}>
      {isOneTime ? 
        <TargetBetweenDatesField text='Target customers who last purchased between' /> : 
        <TargetDaysAgoField text='Target customers who purchased' defaultValue={30} />}
      <IncludePurchasedProductsField defaultValue={false} />
      <TiledRadioField name='strategy' label='Audience Targeting' choices={strategyOptions} defaultValue={strategyOptions[0].value} />
    </Form>
  );
}

function WinbackForm({ id, onSubmit }) {
  const { formData, validate } = useFormContext();
  const isOneTime = formData['is_one_time'] === campaignFrequency.ONE_TIME.value;

  const validateAndSubmit = () => {
    const schema = {...dateRangeOrDaysAgoSchema(isOneTime), 
      'include_purchased_products': {required: 'Empty!'},
    };
    
    if (validate(schema)) {
      onSubmit();
    }
  };

  return (
    <Form id={id} onSubmit={validateAndSubmit}>
      {isOneTime ? 
        <TargetBetweenDatesField text='Target customers who last purchased between' /> : 
        <TargetDaysAgoField text='Target customers who purchased' defaultValue={90} />}
      <IncludePurchasedProductsField defaultValue={false} />
    </Form>
  );
}

function ApiWebhookForm({ id, onSubmit, setLoading }) {
  const { formData, validate, setValue } = useFormContext();
  const [segments, setSegments] = useState([]);
  const [segmentSize, setSegmentSize] = useState();
  
  useEffect(() => {
    const getSegments = async () => {
      setLoading(true);
      const response = await CampaignService.getSegmentList(formData['company']);
      setLoading(false);
      if (response.success) {
        if (response.data.segmentIds.length == 0) {
          toast.error('No segments available, please upload data to a segment');
        }
        setSegments(response.data.segmentIds.map((v) => ({label: v, value: v})));
        if (formData['list_id']) {
          if (!response.data.segmentIds.includes(formData['list_id'])) {
            setValue('list_id', '');
          } else {
            getSegmentSize(formData['list_id']);
          }
        }
      } else {
        toast.error(response.error.error);
      }
    };
    getSegments();
  }, []);
  
  const getSegmentSize = async (segmentId) => {
    setLoading(true);
    const response = await CampaignService.checkSegmentSize(formData['company'], segmentId);
    if (response.success) {
      setSegmentSize(response.data.size);
    } else {
      toast.error(response.error.error);
    }
    setLoading(false);
  };

  const validateAndSubmit = () => {
    const schema = {'list_id': {required: 'Empty!'}};
    if (validate(schema)) {
      onSubmit();
    }
  };

  return (
    <Form id={id} onSubmit={validateAndSubmit}>
      <Input type='dropdown' name='list_id' 
        label={`Segment ${segmentSize != null ? `(${segmentSize} mailable)` : ''}`}
        choices={segments} onChange={getSegmentSize}
        placeholder='Select a segment' />
    </Form>
  );
}

export { KlaviyoForm, ABDForm, NBPForm, WinbackForm, ApiWebhookForm };
