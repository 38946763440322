import React from 'react';

import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './CampaignSummary.module.scss';
import { campaigns, audienceTargeting, campaignFrequency } from './createCampaignEnums';
import { PAPERPLANES_ID } from '../../config';
import { usePermissions } from '../../hooks/usePermissions';

function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function CampaignField({label, data}) {
  return (<div>
    <div className={styles.summaryFieldLabel}>{label}</div>
    <div>{data}</div>
  </div>);
}

function DateRangeOrDaysAgoSummary({ data }) {
  return (<>
    {data['is_one_time'] === campaignFrequency.ONE_TIME.value ? 
      <>
        <CampaignField label='Target from date' data={data['target_from_date']} />
        <CampaignField label='Target to date' data={data['target_to_date']} />
      </> : 
      <CampaignField label='Target days ago' data={data['target_days_ago']} />
    }
  </>);
}


function CampaignTypeSummaryFields({ data }) {
  console.log(data);
  switch (data['campaign_type']) {
    case campaigns.KLAVIYO.value:
      return (
        <CampaignField label='Segment ID' data={data['list_id']} />
      );
    case campaigns.ABD.value:
      return (<DateRangeOrDaysAgoSummary data={data} />);
    case campaigns.NBP.value:
      return <>
        <DateRangeOrDaysAgoSummary data={data} />
        <CampaignField label='Include purchased products' data={data['include_purchased_products']? 'Yes' : 'No'} />
        <CampaignField label='Strategy' data={audienceTargeting.getDisplayName(capitalize(data['strategy']))} />
      </>;
    case campaigns.WINBACK.value:
      return <>
        <DateRangeOrDaysAgoSummary data={data} />
        <CampaignField label='Include purchased products' data={data['include_purchased_products']? 'Yes' : 'No'} />
      </>;
  }
}

function SummarySection({ title, children }) {
  return (<div className={styles.summarySection}>
    <div className={styles.summaryTitle}>{title}</div>
    <div className={styles.divider} />
    <div className={styles.summaryGroup}>
      {children}
    </div>
  </div>);
}

function EditButton({ goToSection }) {
  return (<FontAwesomeIcon icon={faPen} className={styles.editIcon} onClick={goToSection} />);
}

function CampaignSummary({ data, companies, goToControls, goToSettings, goToCreatives }) {
  const { userPermissions } = usePermissions();
  const isPPAccount = userPermissions.companyId === PAPERPLANES_ID;
  return (<div className={styles.campaignSummary}>
    <SummarySection title={<>
      Campaign Settings
      <EditButton goToSection={goToSettings} />
    </>}>
      <div className={styles.summaryFields}>
        <CampaignField label='Name' data={data['campaign_name']} />
        <CampaignField label='Type' data={campaigns.getDisplayName(data['campaign_type'])} />
        {isPPAccount && <CampaignField label='Company' data={companies.find((item) => item.value === data['company']).label} />}
        <CampaignField label='Audience Targeting' 
          data={data['is_one_time'] === campaignFrequency.DAILY.value ? 
          campaignFrequency.DAILY.displayName : campaignFrequency.ONE_TIME.displayName} />
        <CampaignTypeSummaryFields data={data} />
      </div>
    </SummarySection>
    <SummarySection title={<>
      Letter & Envelope
      <EditButton goToSection={goToCreatives} />
    </>}>
      {data['is_a_b_split'] ? <>
        {/* <div className={styles.summaryFields}><b>Variant A</b></div> */}
        <div className={styles.summaryFields}>
          <CampaignField label='Variant A Name' data={data['variant_a_name']} />
          <CampaignField label='Variant A Split' data={`${data['a_b_split']} %`} />
          <CampaignField label='Letter A' data={data['letter_path_a']} />
          <CampaignField label='Envelope A' data={data['envelope_path_a']} />
        </div>
        <hr />
        <div className={styles.summaryFields}>
          <CampaignField label='Variant B Split' data={data['variant_b_name']} />
          <CampaignField label='Variant B Split' data={`${100-data['a_b_split']} %`} />
          <CampaignField label='Letter B' data={data['letter_path_b']} />
          <CampaignField label='Envelope B' data={data['envelope_path_b']} />
        </div></> :
        <div className={styles.summaryFields}>
          <CampaignField label='Letter' data={data['letter_path_a']} />
          <CampaignField label='Envelope' data={data['envelope_path_a']} />
          <br />
        </div>}
    </SummarySection>
    <SummarySection title={<>
      Campaign Controls
      <EditButton goToSection={goToControls} />
    </>}>
      <div className={styles.summaryFields}>
        <CampaignField label='Suppression' data={data['suppression_enabled'] ? `${data['suppression_period']} days` : 'None'} />
        <CampaignField label='Control Split' data={data['control_split_enabled'] ? `${data['control_split']} %` : 'None'} />
        <CampaignField label='Daily Send Limit' data={data['send_limit_enabled'] ? `${data['daily_send_limit']} mails/day` : 'None'} />
      </div>
    </SummarySection>
  </div>);
};

export default CampaignSummary;
