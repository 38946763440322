import ApiService from './ApiService';

const CsvService = {
  getCsvList: async (companyId, page) => {
    return await ApiService.get(`/importers/company/${companyId}/source-csv/`, {}, { page });
  },
  uploadCsv: async (companyId, file) => {
    const formData = new FormData();
    formData.append('file', file);
    return await ApiService.postFormData(`/importers/company/${companyId}/source-csv/`, formData);
  },
  updateColumnMapping: async (companyId, fileId, mapping) => {
    return await ApiService.patch(
        `/importers/company/${companyId}/source-csv/${fileId}/map-columns/`, 
        {columns_mapping: mapping});
  },
};

export default CsvService;
