import React, { useState } from 'react';

import { Menu } from '@headlessui/react';
import { toast } from 'react-toastify';

import styles from './CampaignMenuButton.module.scss';
import MenuButton from './MenuButton';
import { PAPERPLANES_ID } from '../../config';
import { useForm, FormProvider } from '../../hooks/useForm';
import { usePermissions } from '../../hooks/usePermissions';
import CampaignService from '../../services/CampaignService';
import CampaignConfirmation from '../CampaignConfirmation/CampaignConfirmation';
import FieldWrapper from '../Form/FieldWrapper';
import Form from '../Form/Form';
import Input from '../Form/Input';
import ABSplitField from '../Inputs/ABSplitField';
import ToggledNumberField from '../Inputs/ToggledNumberField';
import ModalPopUp from '../Modal/ModalPopUp';
import Spinner from '../Spinner/Spinner';


function handleErrorResponse(response) {
  if (response.error && response.error.errors) {
    const errorMessages = response.error.errors.map((err) => err.detail).join('\n');
    toast.error(errorMessages);
  }
}

function convertKeysToSnakeCase(obj) {
  const snakeCaseObj = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const snakeCaseKey = key.replace(/([A-Z])/g, '_$1').toLowerCase();
      snakeCaseObj[snakeCaseKey] = obj[key];
    }
  }
  return snakeCaseObj;
}

function DeleteCampaignPopUp({ campaignId, campaignData, refresh, close }) {
  const [text, setText] = useState('');
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);
  const data = convertKeysToSnakeCase(campaignData);

  const archiveCampaign = async () => {
    setLoading(true);
    try {
      const newData = {...data, status: 'Archived'};
      const response = await CampaignService.changeCampaignStatus(campaignId, newData);
      if (response.success) {
        refresh();
        close();
      } else handleErrorResponse(response);
    } catch (e) {
      toast.error('An error occured archiving the campaign');
    }
    setLoading(false);
  };

  const onSubmit = () => {
    if (text === 'permanently archive') archiveCampaign();
    else setErrorMessage('Invalid!');
  };
  const onChange = (value) => {
    setText(value);
    if (errorMessage) setErrorMessage('');
  };

  return (<ModalPopUp isOpen={true} onClose={close} title='Archive forever?' disabled={loading}
    description='This action cannot be reversed. If you are absolutely sure please type "permanently archive" in the box.'>
    <Spinner loading={loading} />
    <div>
      <FieldWrapper errorMessage={errorMessage} className={styles.deleteInput}>
        <label htmlFor='archive'>Are you sure?</label>
        <input id='archive' type='text' value={text} 
          onChange={(e) => onChange(e.target.value)}
          placeholder='permanently archive' />
      </FieldWrapper>
    </div>
    <div className={styles.buttonRightContainer}>
      <button className={styles.formButton} type='button' onClick={close}>CLOSE</button>
      <button className={styles.formButton} type='button' onClick={onSubmit}>CONFIRM</button>
    </div>
  </ModalPopUp>);
}

function EditCampaignPopUp({ campaignId, campaignData, refresh, close }) {
  const { userPermissions } = usePermissions();
  const isPPAccount = userPermissions.companyId === PAPERPLANES_ID;
  const data = convertKeysToSnakeCase(campaignData);
  const methods = useForm({...data, 
    'control_split_enabled': true, 'suppression_period_enabled': true,
    'daily_send_limit_enabled': data['daily_send_limit'] != 0,
    'b_split': 100-data['a_b_split']});
  const [onFormPage, setOnFormPage] = useState(true);
  const [loading, setLoading] = useState(false);
  const isABSplit = methods.formData['a_b_split'] !== 100;
  
  const handleNext = () => {
    const validation = {'campaign_name': {required: 'Empty!'}};
    if (methods.formData['suppression_period_enabled']) {
      validation['suppression_period_enabled'] = {required: 'Empty!', min: 0, max: 365};
    }
    if (methods.formData['control_split_enabled']) validation['control_split'] = {required: 'Empty!', min: 0, max: 99};
    if (methods.formData['daily_send_limit_enabled']) validation['daily_send_limit'] = {required: 'Empty!', min: 0};
    if (isABSplit) {
      validation['variant_a_name'] = {required: 'Empty!'};
      validation['variant_b_name'] = {required: 'Empty!'};
      validation['a_b_split'] = {required: 'Empty!', min: 0, max: 100};
    }
    if (methods.validate(validation)) setOnFormPage(false);
  };

  const editCampaign = async (data) => {
    setLoading(true);
    // set to default
    if (!data['control_split_enabled']) data['control_split'] = 0;
    if (!data['suppression_period_enabled']) data['suppression_period'] = 30;
    if (!data['daily_send_limit_enabled']) data['daily_send_limit'] = 0;
    try {
      const response = await CampaignService.editCampaign(campaignId, data);
      if (response.success) {
        toast.success('Campaign edited successfully');
        refresh();
        close();
      } else handleErrorResponse(response);
    } catch (e) {
      toast.error('An error occured editing selected campaign');
    }
    setLoading(false);
  };

  return (<ModalPopUp isOpen={true} onClose={close} title='Edit Campaign' disabled={loading}>
    {loading && <Spinner loading={loading} />}
    <FormProvider value={methods}>
      <Form id='editForm' onSubmit={() => onFormPage ? handleNext() : editCampaign(methods.formData)}>
        {onFormPage && <div className={styles.fieldsContainer}>
          {isPPAccount && <Input type='text' name='company' label='Client' disabled={true} />}
          <Input type='text' name='campaign_name' label='Campaign Name' placeholder='Name of the campaign' />
          {isABSplit && <ABSplitField />}
          <ToggledNumberField name='suppression_period' label='Suppression Period' />
          <ToggledNumberField name='control_split' label='Control Split' />
          <ToggledNumberField name='daily_send_limit' label='Daily Send Limit' />
        </div>}
        {!onFormPage && <CampaignConfirmation data={methods.formData} isABSplit={isABSplit} />}
      </Form>
    </FormProvider>
    <div className={styles.buttonRightContainer}>
      <button className={styles.formButton} type='button' 
        onClick={() => onFormPage ? close() : setOnFormPage(true)}>
        {onFormPage ? 'CLOSE' : 'BACK'}
      </button>
      <button className={styles.formButton} type='submit' form='editForm'>{onFormPage ? 'NEXT' : 'CONFIRM'}</button>
    </div>
  </ModalPopUp>);
}

function EditCampaignStatusPopUp({ campaignId, campaignData, refresh, close }) {
  const data = convertKeysToSnakeCase(campaignData);
  const [loading, setLoading] = useState(false);
  const changeCampaignStatus = async () => {
    setLoading(true);
    try {
      const newData = {...data, status: data.status === 'Active' ? 'Inactive' : 'Active'};
      const response = await CampaignService.changeCampaignStatus(campaignId, newData);
      if (response.success) {
        refresh();
        close();
      } else handleErrorResponse(response);
    } catch (e) {
      toast.error('An error occured changing selected campaign\'s status');
    }
    setLoading(false);
  };
  return (<ModalPopUp isOpen={true} 
    close={close} disabled={loading}
    title={`${data.status === 'Active' ? 'Pause' : 'Reactivate'} Campaign`}
    description='Are you sure?'>
    <Spinner loading={loading} />
    <div className={styles.buttonRightContainer}>
      <button className={styles.formButton} type='button' onClick={close}>NO</button>
      <button className={styles.formButton} type='button' onClick={changeCampaignStatus}>YES</button>
    </div>
  </ModalPopUp>);
}


function CampaignMenuButton({ campaignData, campaignId, refresh }) {
  const data = convertKeysToSnakeCase(campaignData);
  const [popUpOpen, setPopUpOpen] = useState();

  const closePopUp = () => setPopUpOpen('');

  return (<>
    <MenuButton>
      <Menu.Item>
        {({ active }) => (
          <button className={`${active ? 'active' : ''}`}
            onClick={() => setPopUpOpen('edit')}
          >Edit Campaign</button>
        )}
      </Menu.Item>
      <Menu.Item>
        {({ active }) => (
          <button className={`${active ? 'active' : ''}`}
            onClick={() => setPopUpOpen('status')}
          >{`${data.status === 'Active' ? 'Pause' : 'Reactivate'} Campaign`}
          </button>
        )}
      </Menu.Item>
      <Menu.Item>
        {({ active }) => (
          <button className={`${active ? 'active' : ''}`} 
            onClick={() => setPopUpOpen('archive')}>
            <span className='delete-button'>Archive Camapign</span>
          </button>
        )}
      </Menu.Item>
    </MenuButton>
    {popUpOpen === 'edit' && <EditCampaignPopUp campaignData={campaignData} campaignId={campaignId} close={closePopUp} refresh={refresh} />}
    {popUpOpen === 'status' && <EditCampaignStatusPopUp campaignId={campaignId} campaignData={campaignData} close={closePopUp} refresh={refresh} />}
    {popUpOpen === 'archive' && <DeleteCampaignPopUp campaignId={campaignId} campaignData={campaignData} close={closePopUp} refresh={refresh} />}
  </>);
}

export default CampaignMenuButton;
