import React, { useState } from 'react';

import styles from './TabLayout.module.scss';

function TabLayout({ tabs }) {
  const [currTab, setCurrTab] = useState(0);
  return (<div className={styles.tabLayout}>
    <span className={styles.tabsContainer}>
      {tabs.map((tab, i) => <div 
        onClick={() => setCurrTab(i)} 
        key={i}
        className={`${styles.tab} ${currTab == i ? styles.activeTab : ''}`} >
        {tab.label}
      </div>)}
    </span>
    <div className={styles.tabContent}>
      {tabs[currTab].render()}
    </div>
  </div>);
}

export default TabLayout;
