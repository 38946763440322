import { retrieveAuthHeader } from './AuthService';
import { BACKEND_API_URL } from '../config';

const makeApiRequest = async (endpoint, options, queryParams=null) => {
  const authHeaders = retrieveAuthHeader();
  const defaultHeaders = {
    'Accept': 'application/json',
    'Content-Type': 'application/json; charset=UTF-8',
    ...authHeaders,
  };

  const headers = { ...defaultHeaders, ...options.headers };

  if (options.noContentType == true) {
    // console.log('delete content-type');
    delete headers['Content-Type'];
    delete options['noContentType'];
    // console.log(headers);
  };

  let queryString = '';
  if (queryParams) {
    const params = new URLSearchParams(queryParams);
    queryString = `?${params.toString()}`;
  }

  try {
    const response = await fetch(`${BACKEND_API_URL}${endpoint}${queryString}`, {
      ...options,
      headers,
    });

    if (response.status === 401) {
      // Dispatch custom event to redirect user to login page
      window.dispatchEvent(new CustomEvent('auth-expired'));
      return { success: false, error: 'Unauthorized' };
    }

    let jsonData = {};
    try {
      jsonData = await response.json();
    } catch (error) {
      // Ignoring JSON parsing error - no JSON from backend
    }

    if (!response.ok) {
      return { success: false, error: jsonData, status: response.status };
    }

    return { success: true, data: jsonData };
  } catch (error) {
    console.error(`Error in request to ${endpoint}:`, error);
    return { success: false, error: error.message };
  }
};


const ApiService = {
  get: (endpoint, headers = {}, queryParams=null) =>
    makeApiRequest(endpoint, { method: 'GET', headers }, queryParams),

  post: (endpoint, body, headers = {}) =>
    makeApiRequest(endpoint, { method: 'POST', headers, body: JSON.stringify(body) }),

  put: (endpoint, body, headers = {}) =>
    makeApiRequest(endpoint, { method: 'PUT', headers, body: JSON.stringify(body) }),

  patch: (endpoint, body, headers = {}) =>
    makeApiRequest(endpoint, { method: 'PATCH', headers, body: JSON.stringify(body) }),

  delete: (endpoint, headers = {}) =>
    makeApiRequest(endpoint, { method: 'DELETE', headers }),
  postFormData: (endpoint, body) =>
    // body must be of type FormData
    // there must be no Content-Type header so the browser can set it
    // automatically along with a boundary
    makeApiRequest(endpoint, {
      method: 'POST',
      body: body,
      noContentType: true,
    }),
};

export default ApiService;
