import React, { useState, useEffect } from 'react';

import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { z } from 'zod';

import styles from './Customer.module.scss';
import Form from '../../components/Form/Form';
import Input from '../../components/Form/Input';
import ListComponent from '../../components/ListComponent/ListComponent';
import ModalPopUp from '../../components/Modal/ModalPopUp';
import Spinner from '../../components/Spinner/Spinner';
import { useForm, FormProvider } from '../../hooks/useForm';
import CustomerService from '../../services/CustomerService';

function GDPR({ companyId }) {
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailList, setEmailList] = useState([]);

  const loadEmailList = async () => {
    setLoading(true);
    const getEmails = await CustomerService.getGDPRRemovalList(companyId);
    if (getEmails.success) {
      setEmailList(getEmails.data);
    } else {
      toast.error('Could not load blacklist');
    }
    setLoading(false);
  };

  useEffect(() => {
    loadEmailList();
  }, [companyId]);

  return (<div>
    {loading && <Spinner loading={loading} />}
    <div className={styles.content}>
      <div className={styles.row}>
        Handle customer consent and manage data removal requests
        <div className={styles.buttonsContainer}>
          <button className={styles.primaryButton} onClick={() => setPopUpOpen(true)}>
            <div className={styles.row}><FontAwesomeIcon icon={faCirclePlus} />Add</div>
          </button>
        </div>
      </div>
      {!loading && <div>
        <ListComponent
          columns={['emailNormal', 'addedAt']}
          data={emailList}
          searchKeys={['email']} />
      </div>}
      {popUpOpen && <GDPRPopUp companyId={companyId} close={() => {
        setPopUpOpen(false);
        loadEmailList();
      }} />}
    </div>
  </div>);
}

function GDPRPopUp({ close, companyId }) {
  const methods = useForm();
  const [loading, setLoading] = useState(false);

  const submit = () => {
    const schema = z.object({email: z.string().min(1, 'Empty!').email('Invalid Email')});

    const valid = methods.validateZod(schema);
    if (valid) {
      removeCustomerData(methods.formData['email']);
    }
  };

  const removeCustomerData = async (email) => {
    setLoading(true);
    const res = await CustomerService.removeCustomerData(companyId, email);
    console.log(res);
    if (res.success) {
      methods.reset(['email']);
      toast.success(`Successfully removed customer data for ${email}`);
    } else {
      toast.error(res.error.errors[0].detail);
    }
    setLoading(false);
  };
  
  return (<>
    {loading && <Spinner loading={loading} />}
    <ModalPopUp onClose={close} isOpen={true} disabled={loading} title='Remove customer data' includeCloseX={true}>
      <FormProvider value={methods}>
        <Form onSubmit={submit} className={styles.form}>
          Enter an email to remove customer data from our system in accordance with GDPR requirements
          <div className={styles.row}>
            <div className={styles.textBoxContainer}>
              <Input type='text' name='email' defaultValue='' placeholder='Enter An Email' />
            </div>
            <button type='submit' className={styles.primaryButton}>Submit</button>
          </div>
        </Form>
      </FormProvider>
    </ModalPopUp>
  </>);
}

export default GDPR;
