import React, { useState, useCallback, useEffect } from 'react';

import { faFile } from '@fortawesome/free-regular-svg-icons';
import { faFileArrowUp, faX, faCheck, faDownload, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Papa from 'papaparse';
import { useDropzone } from 'react-dropzone';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import styles from './Upload.module.scss';
import Form from '../../components/Form/Form';
import ListComponent from '../../components/ListComponent/ListComponent';
import PaginatedDataTable from '../../components/ListComponent/PaginatedDataTable';
import ModalPopUp from '../../components/Modal/ModalPopUp';
import Spinner from '../../components/Spinner/Spinner';
import TabLayout from '../../components/TabLayout/TabLayout';
import { PAPERPLANES_ID } from '../../config';
import { useForm } from '../../hooks/useForm';
import { usePermissions } from '../../hooks/usePermissions';
import CsvService from '../../services/CsvService';
import PdfService from '../../services/PdfService';
import { toUkDate } from '../../utils/date';
import { formatBytes } from '../../utils/file';

function Upload() {
  const {userPermissions} = usePermissions();
  const isPPAccount = userPermissions.companyId === PAPERPLANES_ID;
  
  return (<>
    {isPPAccount? <PPView /> : <ClientView />}
  </>
  );
}

function ClientView() {
  return (
    <TabLayout tabs={[
      {label: 'Creative', render: () => <ClientPdfs />},
      {label: 'Transaction data', render: () => <ClientTransactionData />},
    ]} />
  );
}

function CsvSelection({ file, setFile, next, close }) {
  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setFile(acceptedFiles[0]);
    }
  }, [file]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'text/csv': ['.csv'] },
    maxFiles: 1,
    onDropRejected: (fileRejections) => {
      if (fileRejections.length > 1) {
        toast.error('Only 1 file is allowed');
      } else {
        toast.error('Invalid file');
      }
    },
  });

  const submit = async () => {
    if (file) {
      next();
    } else {
      toast.error('No CSV file selected');
    }
  };

  return (<>
    <span className={styles.row}>
      <p>Choose a transaction data CSV to upload</p>
      <Link to='/transaction_data_sample.csv' target='_blank' download
        className={styles.downloadButton}>
        Sample CSV <FontAwesomeIcon icon={faDownload} />
      </Link>
    </span>
    <div {...getRootProps({className: `${styles.dragAndDrop}`})}>
      <input {...getInputProps()} />
      <div>
        <h2>
          <FontAwesomeIcon icon={faFileArrowUp} size='2x'/><br />
          Drag and drop<br />
          or <span className={styles.chooseFile}>choose a CSV</span>
        </h2>
      </div>
    </div>
    <div className={styles.files}>
      {file && <File file={file} remove={() => setFile(null)} />}
    </div>
    <div className={styles.buttonsContainer}>
      <button onClick={close} className={styles.secondaryButton}>Close</button>
      <button disabled={file == null} className={styles.primaryButton} onClick={submit}>Next</button>
    </div>
  </>);
}

function ColumnMapping({ prev, file, setLoading }) {
  const columns = [
    {name: 'first_name', required: false},
    {name: 'last_name', required: false},
    {name: 'address1', required: true},
    {name: 'address2', required: false},
    {name: 'city', required: true},
    {name: 'postcode', required: true},
    {name: 'email', required: true},
    {name: 'order_id', required: true},
    {name: 'order_date', required: true},
    {name: 'transaction_amount', required: true},
    {name: 'marketing_status', required: false},
    {name: 'discount_code', required: false},
  ];
  const [headers, setHeaders] = useState(null);
  const [dataMapping, setDataMapping] = useState(null);
  const { formData, setFormData, setValue, errors, register, validate } = useForm();
  const { userPermissions } = usePermissions();

  const getHeaders = (res) => {
    const results = res.data;
    setHeaders(results);
    const standardizedHeaders = results.map((h) => h.toLowerCase().replace(/[^a-zA-Z1-9]/g, ''));
    const initial = {};
    const usedHeaders = new Set();
    columns.forEach((c) => {
      const name = c.name.toLowerCase().replace(/[^a-zA-Z1-9]/g, '');
      const index = standardizedHeaders.indexOf(name);
      if (index != -1 && !usedHeaders.has(results[index])) {
        initial[c.name] = results[index];
        usedHeaders.add(results[index]);
      }
    });
    setFormData((prev) => ({...prev, ...initial}));
    return results;
  };

  const getFirstRow = (res, headerList) => {
    const mapping = {};
    headerList.forEach((h, i) => {
      mapping[h] = res.data[i];
    });
    setDataMapping(mapping);
  };
  
  useEffect(() => {
    let processedHeaders = null;
    Papa.parse(file, {
      // quoteChar: '"',
      step: (result, parser) => {
        setLoading(true);
        if (!processedHeaders) {
          processedHeaders = getHeaders(result);
        } else {
          getFirstRow(result, processedHeaders);
          setLoading(false);
          parser.abort();
        }
      },
      complete: () => {
        setLoading(false);
        console.log('completed');
      },
      error: (error) => {
        toast.error('Error parsing CSV');
        close();
      },
    });
  }, []);

  const onSubmit = async () => {
    const schema = {};
    columns.forEach((c) => {
      if (c.required) schema[c.name] = {required: 'Required'};
    });
    if (!validate(schema)) {
      toast.error('Some columns are missing headers');
      return;
    }
    const columnsMapping = {};
    Object.keys(formData).filter((c) => formData[c] != '')
        .forEach((c) => columnsMapping[formData[c]] = c);
    
    setLoading(true);
    const uploadCsv = await CsvService.uploadCsv(userPermissions.companyId, file);
    if (!uploadCsv.success) {
      toast.error('Error uploading CSV');
      return;
    }
    const updateMapping = await CsvService.updateColumnMapping(userPermissions.companyId, uploadCsv.data.id, columnsMapping);
    if (updateMapping.success) {
      toast.success('Successfully uploaded CSV');
      setTimeout(() => window.location.reload(), 1000);
    } else {
      toast.error('Error with column mapping');
      setLoading(false);
    }
  };

  
  return (<Form onSubmit={onSubmit}>
    <p>Please match each of our required columns to one of your CSV headers</p>
    <div className={styles.formContainer}>
      <div className={styles.columnMappings}>
        <div className={styles.columnMappingsHeader}>
          <span>Column</span>
          <span>CSV header</span>
          <span>Data preview</span>
        </div>
        {columns.map((c, i) => <div className={styles.mapping} key={i}>
          <div className={styles.columnName}>
            {c.name} {!c.required && <span className={styles.optional}>(optional)</span>}
          </div>
          <select 
            className={`${formData[c.name] == '' ? styles.selectedNone : ''} ${errors[c.name] ? styles.error : ''}`}
            placeholder='Select' {...register(c.name, '')} onChange={(e) => setValue(c.name, e.target.value)}>
            <option style={{display: 'none'}} value='' disabled>
              Select a header
            </option>
            <option value=''>None</option>
            {headers && headers
                .filter((h) => h == formData[c.name] || !Object.values(formData).includes(h))
                .map((h, i) => <option key={i} value={h}>{h}</option>)}
          </select>
          <div className={styles.dataPreview}>{formData[c.name] && dataMapping && dataMapping[formData[c.name]]}</div>
        </div>)}
      </div>
    </div>
    <div className={styles.buttonsContainer}>
      <button type='button' onClick={prev} className={styles.secondaryButton}>Back</button>
      <button type='submit' className={styles.primaryButton}>Submit</button>
    </div>
  </Form>);
}

function TransactionCsvPopUp({ close }) {
  const [file, setFile] = useState(null);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [confirmPopUpOpen, setConfirmPopUpOpen] = useState(false);
  const stepLabels = ['Select CSV', 'Map columns'];

  return (<>
    <ModalPopUp title='Upload your transaction data' 
      isOpen={true} 
      disabled={loading}
      onClose={page == 0 ? close : () => setConfirmPopUpOpen(true)}
      maxWidth={page == 0 ? '30em' : '50em'}>
      {loading && <Spinner loading={true} />}
      <div className={styles.content}>
        <div className={styles.stepIndicator}>
          {stepLabels.map((label, index) => <div key={index} 
            className={`${styles.step} ${index == page ? styles.currentStep : ''} ${index < page ? styles.completedStep : ''}`}>
            <div className={styles.stepCircle}>
              {index < page ? <FontAwesomeIcon icon={faCheck} /> : (index+1)}
            </div>
            <div className={styles.stepLabel}>{label}</div>
          </div>)}
        </div>
        {page == 0 && <CsvSelection file={file} setFile={setFile} next={() => setPage(1)} close={close} />}
        {page == 1 && <ColumnMapping file={file} prev={() => setConfirmPopUpOpen(true)} setLoading={setLoading} />}
      </div>
      {confirmPopUpOpen && <ConfirmationPopUp 
        onCancel={() => setConfirmPopUpOpen(false)} 
        onConfirm={() => {
          setPage(0);
          setConfirmPopUpOpen(false);
        }} />}
    </ModalPopUp>
  </>);
}

function ConfirmationPopUp({ onCancel, onConfirm }) {
  return (<ModalPopUp isOpen={true} onClose={onCancel}
    title='Go back to CSV selection?' maxWidth='400px'>
    You will lose your progress
    <div className={styles.popUpButtonsContainer}>
      <button onClick={onCancel}>No</button>
      <button onClick={onConfirm} className={styles.nextButton}>Yes</button>
    </div>
  </ModalPopUp>);
}

function ClientTransactionData() {
  const { userPermissions } = usePermissions();
  const [popUpOpen, setPopUpOpen] = useState(false);

  const columns = [
    {
      name: 'file name',
      cell: (item) => <span className={styles.blueText}>{item.fileName}</span>,
    },
    {
      name: 'date uploaded',
      selector: (item) => toUkDate(item.createdAt),
    },
    {
      name: 'status',
      selector: (item) => item.status,
    },
  ];
  

  const loadFiles = async (page, pageSize) => {
    const getCsvList = await CsvService.getCsvList(userPermissions.companyId, page, pageSize);
    if (!getCsvList.success) {
      toast.error('Failed to load files. Please try again.');
    }
    return getCsvList;
  };
  
  return (<>
    <div className={styles.content}>
      <div className={styles.header}>
        <p>Upload your transaction data as CSV files</p>
        <button onClick={() => setPopUpOpen(true)} className={styles.uploadButton}>
          <span>Upload</span>
          <FontAwesomeIcon icon={faUpload} />
        </button>
      </div>
      <PaginatedDataTable columns={columns} getData={loadFiles} />
      {popUpOpen && <TransactionCsvPopUp close={() => setPopUpOpen(false)} />}
    </div>
  </>);
}

function ClientPdfs() {
  const [pdfList, setPdfList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState([]);

  const columns = [
    'fileName', 'dateUploaded', 'fileSize',
  ];

  const onDrop = useCallback((acceptedFiles) => {
    setFilesToUpload(acceptedFiles);
  }, [filesToUpload]);

  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': [],
    },
  });

  const removeFile = (file) => {
    const newFiles = [...filesToUpload];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFilesToUpload(newFiles);
  };

  const removeAll = () => {
    setFilesToUpload([]);
  };

  const submitFiles = async () => {
    if (filesToUpload.length < 1) {
      toast.error('No PDFs selected');
      return;
    }
    const uploadedPdf = await PdfService.uploadPdfs(filesToUpload);
    for (const resp of uploadedPdf) {
      if (resp.success) toast.success('PDF uploaded successfully');
      else toast.error('Failed to upload PDF');
    }
    loadPDFs();
    removeAll();
  };

  const loadPDFs = async () => {
    setLoading(true);
    const getPdfList = await PdfService.getPdfOrCompanyList();

    if (getPdfList.success) {
      setPdfList(getPdfList.data.pdfFiles);
    } else {
      toast.error('Failed to load PDFs. Please try again.');
    };
    setLoading(false);
  };

  useEffect(() => {
    loadPDFs();
  }, []);

  const searchKeys = ['fileName'];

  return (
    <div className={styles.container}>
      <div className={styles.tableContainer}>
        {!loading && <ListComponent
          columns={columns}
          data={pdfList}
          searchKeys={searchKeys}
          searchBarFullSize={true}
        />}
        <Spinner loading={loading} />
      </div>
      <div className={styles.uploadContainer}>
        <div className={styles.upload}>
          <div {...getRootProps({className: `${styles.dragAndDrop} dropzone`})}>
            <input {...getInputProps()} />
            <h2>
              <FontAwesomeIcon icon={faFileArrowUp} size='2x'/><br />
              Drag & Drop PDFs here,<br />
              or <span className={styles.chooseFile}>choose PDFs</span>
            </h2>
          </div>
          <div className={styles.buttonsContainer}>
            <button className={styles.secondaryButton} onClick={removeAll}>Clear</button>
            <button className={styles.primaryButton} onClick={submitFiles}>Submit</button>
          </div>
          <div className={styles.files}>
            {filesToUpload.map((file) => <File key={file.path} file={file} remove={() => removeFile(file)} />)}
          </div>
        </div>
      </div>
    </div>
  );
}

function PPView() {
  const [loading, setLoading] = useState(false);
  const [companyList, setCompanyList] = useState([]);

  const columns = ['companyUploads'];

  const loadCompanies = async () => {
    setLoading(true);
    const getCompanyList = await PdfService.getPdfOrCompanyList();

    if (getCompanyList.success) {
      setCompanyList(getCompanyList.data.companies);
    } else {
      toast.error('Failed to load companies. Please try again.');
    };
    setLoading(false);
  };

  useEffect(() => {
    loadCompanies();
  }, []);

  const searchKeys = ['name'];

  return (<div className={styles.mainContent}>
    <div className={styles.tableHeader}>
      <h1 className={styles.heading}>Uploads</h1>
    </div>
    <div className={styles.ppTable}>
      <div style={{overflow: 'auto', height: '80vh'}}>
        <p>Select a company</p>
        {!loading && companyList && 
        (<ListComponent
          columns={columns}
          data={companyList}
          searchKeys={searchKeys}
          searchBarFullSize={true}
        />)}
      </div>
    </div>
    <Spinner loading={loading} />
  </div>);
}

function File({ file, remove }) {
  return (<div className={styles.file}>
    <FontAwesomeIcon className={styles.fileIcon} icon={faFile} />
    <span className={styles.fileName}>{file.name}</span>
    <span className={styles.right}>
      <span className={styles.fileSize}>{formatBytes(file.size)}</span>
      <button onClick={remove} className={styles.removeFileButton}>
        <FontAwesomeIcon icon={faX} />
      </button>
    </span>
  </div>);
}

export default Upload;
