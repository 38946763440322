import React from 'react';

import styles from './Tooltip.module.scss';

function Tooltip({ position='right', toolTipContent, children }) {
  return (<span className={styles.tooltipTrigger}>
    {children}
    <span className={`${styles.tooltip} ${styles[position]}`}>
      {toolTipContent}
    </span>
  </span>);
}

export default Tooltip;
