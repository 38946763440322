import { useState } from 'react';

import { toast } from 'react-toastify';

import CompanyService from '../services/CompanyService';

function useCompanyOptions() {
  const [companyOptions, setCompanyOptions] = useState([]);

  const loadCompanyOptions = async () => {
    const getCompanyListResult = await CompanyService.getCompanyList();
    if (getCompanyListResult.success) {
      const companies = getCompanyListResult.data.filter(
          (company) => company.status === 'Active',
      ).map((company) => {
        return {label: company.name, value: company.mIdsite};
      });
      setCompanyOptions(companies.sort((a, b) => {
        const labelA = a.label.toLowerCase();
        const labelB = b.label.toLowerCase();
        if (labelA < labelB) return -1;
        if (labelA > labelB) return 1;
        return 0;
      }));
    } else {
      toast.error('Failed to load companies. Please try again.');
      setCompanyOptions([{label: 'Could not load companies', value: ''}]);
    }
    return getCompanyListResult.success;
  };
  
  return {
    companyOptions,
    loadCompanyOptions,
  };
}

export default useCompanyOptions;
