import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import styles from './CampaignList.module.scss';
import ListComponent from '../../components/ListComponent/ListComponent';
import Spinner from '../../components/Spinner/Spinner';
// import { PAPERPLANES_ID } from '../../config';
import { usePermissions } from '../../hooks/usePermissions';
import CampaignService from '../../services/CampaignService';

const CampaignList = () => {
  const [campaignList, setCampaignList] = useState(null);
  const [loading, setLoading] = useState(false);
  const {userPermissions} = usePermissions();
  const isAdmin = userPermissions.role === 'Admin';
  // const isPPAcount = userPermissions.companyId === PAPERPLANES_ID;

  const columns = [
    'campaignName',
    'company',
    'campaignType',
    'dataSource',
    'activationTime',
    'abSplit',
    'lastRun',
    'lettersCreated',
    'status',
    'campaignActions',
  ];

  /* const saveCampaignDraft = async (data) => {
    if (!data) return;
    try {
      console.log(`Saving campaign as a draft: ${data}`);
      // remove unneccessary fields when sending eg. isTrusted

      const response = await CampaignService.createCampaignDraft({});

      if (response.success) {
        // TODO: reload to clear the form and load new campaign with draft status
        // location.reload();
      } else {
        if (response.error && response.error.errors) {
          const errorMessages = response.error.errors.map((err) => err.detail);
          toast.error(errorMessages);
        }
      }
    } catch (e) {
      toast.error('An error occured creating campaign as a draft');
    }
  }; */

  const loadCampaignList = async () => {
    setLoading(true);
    const getCampaignList = await CampaignService.getCampaignList();

    if (getCampaignList.success) {
      setCampaignList(getCampaignList.data.filter((campaign) => campaign.status != 'Archived')
          .map((campaign) => ({
            ...campaign,
            refresh: loadCampaignList,
          })));
    } else {
      toast.error('Failed to load campaigns. Please try again');
    }
    setLoading(false);
  };

  useEffect(() => {
    loadCampaignList();
    // if (isPPAcount) loadCompanyList();
    // saveCampaignDraft(localStorage.getItem('campaignDraft'));
    // localStorage.removeItem('campaignDraft');
  }, []);

  const searchKeys = ['campaignName'];

  return (
    <div className={styles.mainContent}>
      <div className={styles.campaignTableHeader}>
        <h1 className={styles.heading}>Campaigns</h1>
        {isAdmin && 
        <Link to="/create-campaign" className={styles.addCampaignButton}>New Campaign</Link>}
      </div>
      <div>
        {!loading && campaignList && (<ListComponent
          columns={columns}
          data={campaignList}
          searchKeys={searchKeys}
        />)}
      </div>
      <Spinner loading={loading} />
    </div>
  );
};

export default CampaignList;
