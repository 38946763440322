import moment from 'moment/moment';

const toUkDate = (dateString) => {
  return moment(dateString).format('DD/MM/YYYY');
};

const toUkDateTime = (dateString) => {
  return moment(dateString).format('DD/MM/YYYY HH:mm');
};

export { toUkDate, toUkDateTime };
