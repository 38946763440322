import React from 'react';

import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { ToastContainer } from 'react-toastify';

import './App.scss';
import MainLayout from './components/MainLayout/MainLayout';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import { PAPERPLANES_ID, userRoles } from './config';
import { usePermissions } from './hooks/usePermissions';
import ApiDataPreview from './pages/ApiDataPreview/ApiDataPreview';
import CampaignList from './pages/CampaignList/CampaignList';
import CompanyList from './pages/CompanyList/CompanyList';
import ConnectKeys from './pages/ConnectKeys/ConnectKeys';
import CreateCampaign from './pages/CreateCampaign/CreateCampaign';
import Customer from './pages/Customer/Customer';
import Dashboard from './pages/Dashboard/Dashboard';
import Error from './pages/Error/Error';
import Login from './pages/Login/Login';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import SetPassword from './pages/SetPassword/SetPassword';
import CompanyUploads from './pages/Upload/CompanyUploads';
import Upload from './pages/Upload/Upload';
import UserList from './pages/UserList/UserList';
import { activateUser } from './services/AuthService';
import UserService from './services/UserService';


function App() {
  const { userPermissions } = usePermissions();
  const isPPAccount = userPermissions.companyId === PAPERPLANES_ID;
  const isAdmin = userPermissions.role === 'Admin';
  const isCrmManager = isAdmin || userPermissions.role === userRoles.CRM_MANAGER;
  const isAnalyst = isCrmManager || userPermissions.role === userRoles.ANALYST;
  const isCustomerSupport = isCrmManager || userPermissions.role === userRoles.CUSTOMER_SUPPORT;
  const isDeveloper = isCrmManager || userPermissions.role === userRoles.DEVELOPER;
  const displayDashboard = userPermissions.displayDashboard;

  return (
    <Router>
      <div className="appContainer">
        <Switch>
          <Route path="/" exact>
            <Redirect to="/login" />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/forgot-password">
            <ResetPassword />
          </Route>
          <Route path="/change-password">
            <SetPassword
              endpoint={UserService.resetPasswordConfirm}
              title='Reset Your Password'
              userMsgs={{
                'success': 'Password successfully changed',
                'catchError': 'An error occured while resetting password',
                'error': 'Resetting password failed. Please try again',
              }}
            />
          </Route>
          <Route path="/activate-account">
            <SetPassword
              endpoint={activateUser}
              title='Activate Your account'
              userMsgs={{
                'success': 'Account Activated',
                'catchError': 'An error occurred during activation.',
                'error': 'Activation failed. Please try again.',
              }}
            />
          </Route>
          <MainLayout>
            <Switch>
              <ProtectedRoute path='/dashboard' condition={isAnalyst && displayDashboard} component={Dashboard} />
              <ProtectedRoute path='/upload/:accountId' condition={isPPAccount} component={CompanyUploads} />
              <ProtectedRoute path='/upload' condition={isAnalyst || isDeveloper} component={Upload} />
              <ProtectedRoute path='/companies' condition={isPPAccount} component={CompanyList} />
              <ProtectedRoute path='/users' condition={isAdmin} component={UserList} />
              <ProtectedRoute path='/campaigns' condition={isPPAccount} component={CampaignList} />
              <ProtectedRoute path='/create-campaign' condition={isPPAccount} component={CreateCampaign} />
              <ProtectedRoute path='/link_keys'
                condition={isDeveloper && !isPPAccount} component={ConnectKeys} />
              <ProtectedRoute path='/api_data_preview'
                condition={isDeveloper && !isPPAccount} component={ApiDataPreview} />
              <ProtectedRoute path='/customers'
                condition={isCustomerSupport} component={Customer} />
              <Route path='*' component={Error} />
              {/* <Redirect to="/" /> */}
            </Switch>
          </MainLayout>
        </Switch>
      </div>
      <ToastContainer />
    </Router>
  );
}

export default App;
