import React from 'react';

import { ClipLoader } from 'react-spinners';


const Spinner = ({ loading, fullscreen = true, size = 100, color = '#6A99CF' }) => {
  const style = { position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1000 };

  return (
    <div style={fullscreen ? style : { display: 'inline-block' }}>
      <ClipLoader size={size} color={color} loading={loading} />
    </div>
  );
};

export default Spinner;
