import React, { useState } from 'react';

import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';

import eyeHiddenIcon from '../../assets/images/eye-hidden.png';
import eyeShownIcon from '../../assets/images/eye-shown.png';
import AuthLayout from '../../components/AuthLayout/AuthLayout';
import PrimaryActionButton from '../../components/Buttons/PrimaryActionButton';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import InputField from '../../components/Inputs/InputField';
import RedirectLink from '../../components/Links/RedirectLink';
import { userRoles } from '../../config';
import useAuth from '../../hooks/useAuth';
import { usePermissions } from '../../hooks/usePermissions';
import { authenticateUser } from '../../services/AuthService';
import UserService from '../../services/UserService';


function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [isAuth, setIsAuth] = useAuth();
  const { userPermissions, changePermissions } = usePermissions();

  const handleLogin = async (e) => {
    e.preventDefault();
    const response = await authenticateUser(email, password);

    if (response.success) {
      const currentUserData = await UserService.getCurrentUserData();
      if (currentUserData.success) {
        changePermissions(currentUserData.data);
        setIsAuth(true);
      } else {
        toast.error('Session expired');
      }
    } else {
      setError(response.error.error || 'Authentication failed. Please try again.');
    }
  };

  if (isAuth) {
    const isAdmin = userPermissions.role === 'Admin';
    const isCrmManager = isAdmin || userPermissions.role === userRoles.CRM_MANAGER;
    const isAnalyst = isCrmManager || userPermissions.role === userRoles.ANALYST;
    const isCustomerSupport = isCrmManager || userPermissions.role === userRoles.CUSTOMER_SUPPORT;
    const isDeveloper = isCrmManager || userPermissions.role === userRoles.DEVELOPER;
    const displayDashboard = userPermissions.displayDashboard;
    if (isAdmin) {
      return displayDashboard ? <Redirect to="/dashboard" /> : <Redirect to='/users' />;
    } else if (isAnalyst) {
      return displayDashboard ? <Redirect to="/dashboard" /> : <Redirect to="/upload" />;
    } else if (isCustomerSupport) {
      return <Redirect to="/customers" />;
    } else if (isDeveloper) {
      return <Redirect to="/link_keys" />;
    } else {
      return <Redirect to='/campaigns' />;
    }
  }

  return (
    <AuthLayout
      title="Welcome back!"
      link={<RedirectLink to="/forgot-password">Forgot your password?</RedirectLink>}
    >

      <form onSubmit={handleLogin}>
        <InputField
          id='email'
          type="email"
          placeholder="Email"
          value={email}
          autoComplete='email'
          onChange={(e) => setEmail(e.target.value)}
        />

        <InputField
          id='password'
          type={showPassword ? 'text' : 'password'}
          placeholder="Password"
          value={password}
          autoComplete='current-password'
          onChange={(e) => setPassword(e.target.value)}
          toggleIcon={showPassword ? eyeShownIcon : eyeHiddenIcon}
          onToggleIconClick={() => setShowPassword(!showPassword)}
        />

        <ErrorMessage messages={error} />

        <PrimaryActionButton type='submit'>SIGN IN</PrimaryActionButton>
      </form>

    </AuthLayout>
  );
}
export default Login;
